.ResultsCard{
    width: 80%;
    -webkit-box-shadow: 0px 15px 17px -22px rgba(0,0,0,0.56);
    -moz-box-shadow: 0px 15px 17px -22px rgba(0,0,0,0.56);
    box-shadow: 0px 15px 17px -22px rgba(0,0,0,0.56);
    border: 1px solid #ddd;
    margin: 0 auto 5% auto;
    transition: all 0.4s ease;
}

.titleBox{
    display: flex;
    align-items: center;
    padding: 3% 5% 3% 5%;
}

.title{
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 3%;
}

.ResultsCard h1{
    font-size: 1.3em;
    word-break: break-word;
}

.ResultsCard i{
    margin-left: auto;
    transition: all 0.4s ease;
}

.rotateArrow{
    transform: rotate(180deg)
}

.description{
    width: 90%;
    margin: 0 auto 10% auto;
}
