.HomePage{
    width: 90%;
    margin-top: 10vh;
    margin-bottom: 2vh;
    background-color: white;
    -webkit-box-shadow: 0px 33px 17px -22px rgba(0,0,0,0.56);
    -moz-box-shadow: 0px 33px 17px -22px rgba(0,0,0,0.56);
    box-shadow: 0px 33px 17px -22px rgba(0,0,0,0.56);
    padding-bottom: 7%;
    max-width: 900px;
    z-index: 1;
}


.header{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.HomePage h1{
    color: rgb(0, 0, 0);
    font-size: 3em;
}

.HomePage img{
    width: 10%;
    height: 10%;
    margin-right: 4%;
}

@media only screen and (max-width: 600px) {
    .HomePage{
        margin-top: 5vh;
    }
    .HomePage h1{
        font-size: 1.5em;
    }
  }

/* width */
::-webkit-scrollbar {
width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: rgb(170, 170, 170);
}