.source{
    background-color: rgb(243, 243, 243);
    padding: 3%;
    font-weight: 700;
    margin-bottom: 2%;
}

.moreInfo{
    padding: 0 0 0 5%;
    margin-bottom: 6%;
}