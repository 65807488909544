.ResultsTable{
    display: inline-block;
    text-align: center;
    width: 100%;
}

.results{
    border: 1px solid #ddd;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 5%;
}

.ResultsTable table{
    table-layout: fixed;
    border-collapse: collapse;
    display: block;
    text-align: center;
}

.ResultsTable th{
    top: 0;
    background-color: #F9FAFB;
    text-align: center;
    padding: 2%;
    font-size: .7em;
    margin-bottom:0;
    margin-top:0;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    .ResultsTable table{
        overflow: auto;
    }
  }

.ResultsTable tbody{
    overflow: auto;
    height: 100px;
}

.ResultsTable td{
    border: 1px solid #ddd;
    text-align: left;
    font-size: .8em;
    width: 10%;
    text-align: center;
    padding: 5px;
}

.ResultsTable td:first-child {
    border-left: none;
}

.ResultsTable td:last-child {
    border-right: none;
}

/* width */
::-webkit-scrollbar {
width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: rgb(170, 170, 170);
}