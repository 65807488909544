.IngrForm{
    margin-top: 3%;
    text-align: center;
  }

.IngrForm h3{
    font-size: 1.5em;
    text-align: left;
    margin-bottom: 4%;
    padding-left: 8%;
}

.IngrForm textarea {
    outline: none;
    width: 80%;
    height: 28vh;
    padding: 2%;
}

.IngrForm textarea::placeholder{
    color: rgb(190, 190, 190)
}

.IngrForm hr{
    height: .05em;
    background-color: #ddd;
    border: none;
    width: 90%;
    margin-top: 5%;
}

.IngrForm h5{
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 3%;
    padding-left: 8%;
}

.checkboxes{
  font-size: .9em;
  display: inline-block;
}

.IngrForm p{
  text-align: left;
  padding-left: 8%;
  padding-right: 8%;
  font-size: .9em;
  margin-bottom: 5%;
}

.button {
  margin-top: 7%;
  outline: none;
  height: 3.5em;
  width: 50%;
  border-radius: 40px;
  font-weight: 700;
  border: 2px solid #FFA177FF;
  background: white;
  color: #FFA177FF;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.button:hover{
  color: white;
  background: #FFA177FF;
}

.button:disabled{
  color: gray;
  border: 2px solid gray;
}

.button:disabled:hover{
  color: white;
  background: gray;
}

.onClick {
    margin-top: 7%;
    outline: none;
    height: 40px;
    border-radius: 40px;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.4s ease;
    width: 40px;
    border: 2px solid lightgray;
    border-top-color: #FFA177FF;
    border-width:3px;
    font-size:0;
    animation: rotating 1s 0.4s linear infinite;
}

@keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }