.ResultsPage {
    width: 90%;
    margin-top: 10vh;
    margin-bottom: 2vh;
    background-color: white;
    -webkit-box-shadow: 0px 33px 17px -22px rgba(0,0,0,0.56);
    -moz-box-shadow: 0px 33px 17px -22px rgba(0,0,0,0.56);
    box-shadow: 0px 33px 17px -22px rgba(0,0,0,0.56);
    padding-bottom: 7%;
    max-width: 900px;
    z-index: 1;
}

.title{
    display: flex;
    align-items: center;
    padding-right: 6%;
}

.ResultsPage h3 {
    text-align: left;
    padding-left: 8%;
    padding-top: 3%;
    width: 80%;
}

.reportButton{
    font-size: .7em;
    margin-left: auto;
    text-align: center;
    line-height: 2%;
    padding-top: 5%;
}

.inputs{
    justify-content: center;
    margin-bottom: 4%;
}

.button {
    outline: none;
    margin: 0 auto;
    margin-top: 5%;
    height: 40px;
    display: block;
    text-align: center;
    line-height: 40px;
    width: 50%;
    border-radius: 40px;
    font-weight: 700;
    border: 2px solid rgb(87, 168, 190);
    background: white;
    color: rgb(87, 168, 190);
    letter-spacing: 1px;
    font-family: 'Muli', sans-serif;
    cursor: pointer;
    transition: all 0.4s ease;
}

.button:hover{
    color: white;
    background: rgb(87, 168, 190);
}

@media only screen and (max-width: 600px) {
    .ResultsPage{
        margin-top: 5vh;
    }
  }

/* width */
::-webkit-scrollbar {
width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: rgb(170, 170, 170);
}